import React, { useEffect, useState } from "react"
import { graphql, Link, navigate } from "gatsby"
import { hyphenated } from "hyphenated"
import de from "hyphenated-de"
import { Helmet } from "react-helmet"
import { isMobile } from "react-device-detect"

import QuoteImgMobile1 from "../../static/images/mobile/01.jpg"
import QuoteImgMobile2 from "../../static/images/mobile/02.jpg"
import QuoteImgMobile3 from "../../static/images/mobile/03.jpg"
import QuoteImgMobile4 from "../../static/images/mobile/04.jpg"
import QuoteImg1 from "../../static/images/01.jpg"
import QuoteImg2 from "../../static/images/02.jpg"
import QuoteImg3 from "../../static/images/03.jpg"
import QuoteImg4 from "../../static/images/04.jpg"
import CareerPlatform from "../../static/images/KarrierePortal.png"
import CareerPlatform2 from "../../static/images/CareerPortal.png"

import Seo from "../components/seo"
import Localize from "../components/localize"
import { CareerPlatformSvg, HomeSvg } from "../svgs/svg"
import EmployeeImage from "../../static/images/user.png"
import { RightArrow, LeftArrow } from "../shared/icons/icons"

const IndexPage = ({
  data: { teams, home, services, site },
  pageContext,
  location,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0)
  const loadScript = () => {
    const script = document.createElement("script")
    const body = document.getElementsByTagName("body")[0]
    script.id = "map-script"
    script.src = "../../scripts/main.js"
    script.type = "text/javascript"
    var scripts = document.getElementsByTagName("script")

    for (var i = scripts.length; i--; ) {
      if (scripts[i].src.includes("main.js")) {
        body.removeChild(scripts[i])
      }
    }

    window.clearInterval(window.autoQuoteTimer)
    body.appendChild(script)
  }

  useEffect(() => {
    loadScript()
    let cardSuffleInterval
    cardSuffleInterval = setInterval(() => {
      if (window.teamcardSuffle) {
        window.teamcardSuffle()
        clearInterval(cardSuffleInterval)
      }
    }, 100)

    return () => {
      window.clearInterval(cardSuffleInterval)
      // window.clearInterval(window.changeTeammemberTimer)
      if (window.changeTeammemberTimer) {
        window.changeTeammemberTimer.stop()
      }

      const body = document.getElementsByTagName("body")[0]
      var scripts = document.getElementsByTagName("script")

      for (var i = scripts.length; i--; ) {
        if (scripts[i].src.includes("main.js")) {
          body.removeChild(scripts[i])
        }
      }
    }
  }, [])

  useEffect(() => {
    if (location.state && location.state.scroll) {
      window.scroll({
        top: location.state.scroll,
        behavior: "smooth",
      })
    }
    if (
      !document.getElementById("map-script") ||
      (location.state && location.state && !location.state.logo)
    ) {
      return <Helmet>{}</Helmet>
    }
  }, [location.state])

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })
    return () => {
      window.removeEventListener("scroll", handleScroll, { passive: true })
    }
  })

  const handleScroll = () => {
    var doc = document.documentElement
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
    setScrollPosition(top)
  }

  const lan = pageContext.locale

  return (
    <>
      <Seo
        lan={lan}
        title="Haackschubert"
        description={
          lan === "en"
            ? "This is the platform where you can find the best solution related to Law"
            : "Dies ist die Plattform, auf der Sie die beste Lösung zum Thema Recht finden"
        }
        url={`${site.siteMetadata.siteUrl}/${lan}/`}
        titleTemplate={
          lan === "en"
            ? "HaackSchubert - Lawyers, tax consultants, auditors and notaries"
            : "HaackSchubert - Rechtsanwälte, Steuerberater, Wirtschaftsprüfer und Notare"
        }
      />
      <div id="content" className="margin--top-svg">
        <div className="header header-typ1 mobile--height-animation">
          <HomeSvg />
          <a className="opacity-0" id={"test"} />
          <div id="quotes" className="index-quotes">
            <div>
              <div className="quotes">
                <div
                  className="quote visible"
                  data-image={isMobile ? QuoteImgMobile1 : QuoteImg1}
                >
                  {home.quote1}
                </div>
                <div
                  className="quote"
                  data-image={isMobile ? QuoteImgMobile2 : QuoteImg2}
                >
                  {home.quote2}
                </div>
                <div
                  className="quote"
                  data-image={isMobile ? QuoteImgMobile3 : QuoteImg3}
                >
                  {home.quote3}
                </div>
                <div
                  className="quote"
                  data-image={isMobile ? QuoteImgMobile4 : QuoteImg4}
                >
                  {home.quote4}
                </div>
              </div>
              <div id="quotesPageButtons">
                <div className="prevQuote noselect">
                  <span className="icon-arrow_left">
                    <LeftArrow fill="white" />
                  </span>
                </div>
                <div className="nextQuote noselect">
                  <span className="icon-arrow_right">
                    <RightArrow height="35" width="35" />
                  </span>
                </div>
              </div>
            </div>
            <div id="quotesPagination" className="noselect">
              <div id="quotePage-0">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div id="quotePage-1">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div id="quotePage-2">
                <div></div>
                <div></div>
                <div></div>
              </div>
              <div id="quotePage-3">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
        <div className="simpletext">
          <div className="floater"></div>
          <h1>
            {home.service_title[0].children[0].text} <br />
            {home.service_title[1].children[0].text}
          </h1>
          {home.service_description.map(desc => {
            return desc.children.map((des, index) => {
              return (
                <p key={index}>
                  {lan === "en"
                    ? hyphenated(des.text)
                    : hyphenated(des.text, { language: de })}
                </p>
              )
            })
          })}
        </div>
        <div id="subpagemenubackground"></div>
        <div className="subpagemenu">
          <div>
            <div
              id="subpagesubs-0"
              className="swiper-container swiper-container-0 subpagesubs d-block"
            >
              <div className="swiper-wrapper home-page-swiper">
                {services.edges.map((service, index) => (
                  <Link
                    key={index}
                    state={{ scroll: 0, home: true }}
                    to={`/${lan}/services/${service.node.slug.current}/`}
                  >
                    <div className="swiper-slide swiperr">
                      <h4>
                        {service.node.title.split("&")[0]}
                        <span className="red">&amp;</span>
                        <br /> {service.node.title.split("&").pop()}
                      </h4>
                      <p className="font--size-16">
                        {lan === "en"
                          ? hyphenated(service.node.subtitle)
                          : hyphenated(service.node.subtitle, { language: de })}
                      </p>
                      <div className="link">
                        <span className="hs_arrow_right">
                          {lan &&
                            site.siteMetadata &&
                            site.siteMetadata[lan].goToDetails}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="teammemberblock">
          <div className="simpletext max--width-1080 pb-1">
            <h1 className="team-ti">{home.team_title}</h1>
          </div>
          <div
            id="teammember"
            className="teammember teammember-shadow res-team-home pro"
          >
            <div className="teamcard team-con">
              <div
                onClick={() => navigate(`/${lan}/team/`)}
                className="t-button"
              >
                <span className="go_to_team-button">
                  {lan === "en" ? "To the Team" : "Zum Team"}
                </span>
              </div>
              {/* <div className="teamcard-back teamcard-red">
                {teams.edges.length}
                <br />
                Professionals
                <button
                  style={{ outline: "none" }}
                  onClick={() => navigate(`/${lan}/team/`)}
                >
                  {lan === "en" ? "Go to team" : "Gehe zum Team"}
                </button>
              </div> */}
            </div>
            {teams.edges.map((team, index) => {
              return (
                <div key={index} className="teamcard team-con">
                  <div className=" teamcard-front team-image">
                    <img
                      className="w-100 h-100 object-fit-cover"
                      loading="lazy"
                      alt="imgg"
                      src={
                        team.node.mainImage
                          ? team.node.mainImage.asset
                            ? `${team.node.mainImage.asset.url}?h=250`
                            : EmployeeImage
                          : EmployeeImage
                      }
                    />
                  </div>
                  <div className="teamcard-back hover-div">
                    {team.node.name}
                    <br />
                    <button
                      onClick={() => [
                        window.sessionStorage.setItem("text-en", "Home"),
                        window.sessionStorage.setItem("text-de", "Startseite"),
                        window.sessionStorage.setItem("route-en", `/en/`),
                        window.sessionStorage.setItem("route-de", `/de/`),
                        navigate(`/${lan}/team/${team.node.slug.current}/`, {
                          scroll: scrollPosition,
                        }),
                      ]}
                    >
                      {lan &&
                        site.siteMetadata &&
                        site.siteMetadata[lan].goToProfile}
                    </button>
                  </div>
                </div>
              )
            })}
          </div>
          <div className="simpletext">
            {home.team_description.map(desc => {
              return desc.children.map((des, index) => {
                return <p key={index}>{des.text}</p>
              })
            })}
          </div>
        </div>
        <div className="header header-typ-vacancy about--us-header h-auto">
          <CareerPlatformSvg />
          <div
            id="headerTextVacancy"
            className="headerText auto-adjust-header about--us-text about-text m-0-60 career-div"
          >
            <img
              src={lan === "de" ? CareerPlatform : CareerPlatform2}
              alt="img"
              className="w-auto object-fit-contain"
              loading="lazy"
              width="400"
              height={"110"}
            />
            <h4>{home.career_title}</h4>
            {home.career_description.map(desc => {
              return desc.children.map((des, index) => {
                return (
                  <p key={index}>
                    {lan === "en"
                      ? hyphenated(des.text)
                      : hyphenated(des.text, { language: de })}
                  </p>
                )
              })
            })}
            <p> </p>
            <Link
              className="button animation"
              state={{ scroll: 0 }}
              to={`/${lan}/career/`}
            >
              <span className="icon-arrow_right p-0">
                <RightArrow />
              </span>{" "}
              {lan &&
                site.siteMetadata &&
                site.siteMetadata[lan].goToCareerPlatform}
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        en {
          goToProfile
          goToDetails
          goToCareerPlatform
        }
        de {
          goToProfile
          goToDetails
          goToCareerPlatform
        }
      }
    }
    teams: allSanityTeamProfile {
      edges {
        node {
          name
          slug {
            current
          }
          mainImage {
            asset {
              url
            }
          }
        }
      }
    }
    services: allSanityMainService(sort: { fields: order, order: ASC }) {
      edges {
        node {
          title {
            _type
            en
            de
          }
          subtitle {
            _type
            en
            de
          }
          slug {
            current
          }
        }
      }
    }
    home: sanityHome {
      quote1 {
        _type
        de
        en
      }
      quote2 {
        _type
        de
        en
      }
      quote3 {
        _type
        de
        en
      }
      quote4 {
        _type
        de
        en
      }
      service_title {
        de {
          children {
            text
          }
        }
        en {
          children {
            text
          }
        }
        _type
      }
      service_description {
        de {
          children {
            text
          }
        }
        en {
          children {
            text
          }
        }
        _type
      }
      team_title {
        _type
        de
        en
      }
      team_description {
        de {
          children {
            text
          }
        }
        en {
          children {
            text
          }
        }
        _type
      }
      career_title {
        _type
        de
        en
      }
      career_description {
        _type
        de {
          children {
            text
          }
        }
        en {
          children {
            text
          }
        }
      }
    }
  }
`
export default Localize(IndexPage)
